import { useState, useEffect } from "preact/hooks";
import Test from "./Test.tsx";

interface Step {
  request: {
    method: string;
    url: string;
    headers: { [key: string]: string };
    body: string;
  };
  response: {
    status: number;
    statusText: string;
    headers: { [key: string]: string };
    body: string;
  };
  assertions: {
    type: string;
    expected: string | number | boolean | object | null;
    actual: string | number | boolean | object | null;
    passed: boolean;
    message: string;
    line: number;
  }[];
  duration: number;
}

interface TestProps {
  name: string;
  steps: Step[];
  result: string;
  sourceCode: string;
  lineOffset: number;
  duration: number;
}

interface TestSuiteProps {
  name: string;
  duration: number;
  tests: TestProps[];
  showPassed: boolean;
  urlMap?: string; // Optional URL map parameter
}

export default function TestSuite({ name, duration, tests, showPassed, urlMap }: TestSuiteProps) {
  const [urlMapData, setUrlMapData] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchUrlMap = async () => {
      try {
        const apiUrl = urlMap?.startsWith("http")
          ? urlMap
          : `/api/data?file=${encodeURIComponent(urlMap || "")}`;
  
        const response = await fetch(apiUrl);
        if (response.status === 404) {
          console.log("URL map file not found.");
          return; // Silently fail for 404 errors
        }
  
        if (!response.ok) {
          // Other non-404 errors can be handled here if needed
          return;
        }
  
        const data = await response.json();
        setUrlMapData(data);
      } catch (_error) {
        // Silently handle any other errors
      }
    };
  
    if (urlMap) fetchUrlMap();
  }, [urlMap]);
  
  

  const filteredTests = showPassed ? tests : tests.filter((test) => test.result !== "pass");

  const findMatchingKey = (testSuiteName: string) => {
    for (const key in urlMapData) {
      if (testSuiteName.includes(key)) {
        return { key, link: urlMapData[key] };
      }
    }
    return null;
  };

  const match = findMatchingKey(name);

  return (
    <div class="mb-8 space-y-2">
      <h2 class="text-2xl font-semibold">
        {match ? (
          <>
            <a href={match.link} target="_blank" rel="noopener noreferrer" class="text-blue-600 hover:text-blue-800">
              {match.key}
            </a>{" "}
            {name.replace(match.key, "")}
          </>
        ) : (
          name
        )}
      </h2>

      <div class="flex items-center space-x-4">
        <span>Duration: {duration.toFixed(1)} ms</span>
        <span class={`text-green-600 ${!showPassed ? "line-through" : ""}`}>
          ✅ {tests.filter((test) => test.result === "pass").length} Passed
        </span>
        <span class="text-red-600">
          ❌ {tests.filter((test) => test.result === "fail").length} Failed
        </span>
      </div>

      {filteredTests.map((test) => (
        <Test key={test.name} {...test} />
      ))}
    </div>
  );
}
