import { useState } from "preact/hooks";

interface AccordionProps {
  title: string;
  children: preact.ComponentChildren;
  initiallyOpen?: boolean; // Optional prop to control the initial state (open/closed)
}

export default function Accordion({ title, children, initiallyOpen = false }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(initiallyOpen); // State to track if the accordion is open or closed

  return (
    <div class="border mb-4  shadow-lg bg-slate-200 dark:bg-slate-800 transition-all">
      {/* Accordion Header */}
      <button
        class="w-full text-left p-2 sm:p-3 bg-slate-200 dark:bg-slate-700 hover:bg-slate-100 dark:hover:bg-slate-600 flex justify-between items-center transition-all"
        onClick={() => setIsOpen(!isOpen)} // Toggle accordion open/closed state
      >
        <span class="font-semibold text-sm sm:text-base md:text-lg text-slate-700 dark:text-slate-200">
          {title} {/* Display the title */}
        </span>
        <span class="text-sm sm:text-base">{isOpen ? "▲" : "▼"}</span> {/* Toggle arrow */}
      </button>

      {/* Accordion Content - only displayed when open */}
      {isOpen && (
        <div class="p-2 sm:p-3 bg-slate-200 dark:bg-slate-900 transition-all">
          {children} {/* Render children content */}
        </div>
      )}
    </div>
  );
}
