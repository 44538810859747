import Accordion from "./Accordion.tsx";
import StepComponent from "./Step.tsx";
import SourceCodeWithLineNumbers from "./SourceCodeWithLineNumbers.tsx"; // Import the new component

// Define the types for Test and Step data
interface StepData {
  request: object;
  response: {
    status: number;
    statusText: string;
    headers: object;
    body: string;
  };
  assertions: {
    type: string;
    expected: string | number | boolean | object | null;
    actual: string | number | boolean | object | null;
    passed: boolean;
    message: string;
    line: number;
  }[];
  duration: number;
}

interface TestProps {
  name: string;
  steps: StepData[];
  result: string;
  sourceCode: string;
  duration: number;
  lineOffset: number; // Added lineOffset to props
}

export default function Test({ name, steps, result, sourceCode, duration, lineOffset }: TestProps) {
  // Define the result icon based on whether the test passed or failed
  const resultIcon = result === "pass" ? "✅" : "❌";

  // Set background color based on the test result
  const bgColor = result === "pass" ? "bg-green-400" : "bg-red-400";

  return (
    <Accordion title={`${resultIcon}  ${name}  (${duration.toFixed(1)} ms)`}>
      <div class={`p-4 ${bgColor}`}>
        {/* Source code section with line numbers */}
        <p class="font-semibold mb-2">Source Code:</p>
        <div class="overflow-x-auto p-0">
          {sourceCode ? (
            <SourceCodeWithLineNumbers
              sourceCode={sourceCode}
              lineOffset={lineOffset}
              assertions={steps.flatMap(step => step.assertions)} // Pass all assertions from steps
            />
          ) : (
            <p>No source code available</p>
          )}
        </div>

        {/* Render each step */}
        {steps.map((step, index) => (
          <StepComponent key={index} {...step} />
        ))}
      </div>
    </Accordion>
  );
}
