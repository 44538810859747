import Accordion from "../components/Accordion.tsx";
import CodeBlock from "./CodeBlock.tsx";
import ClipboardButton from "./ClipboardButton.tsx"; // Import the ClipboardButton component

// Define the type for Assertions
interface Assertion {
  line: number;
  passed: boolean;
  message: string;
  expected: string | number | boolean | object | null;
  actual: string | number | boolean | object | null;
}

interface StepProps {
  request: object;
  response: {
    status: number;
    statusText: string;
    headers: object;
    body: string;
  };
  assertions: Assertion[];
  duration: number;
}

export default function StepComponent({ request, response, assertions, duration }: StepProps) {
  // Try to pretty print the response body as JSON, or return it as plain text if not valid JSON
  const prettyBody = (() => {
    try {
      return JSON.stringify(JSON.parse(response.body), null, 2); // JSON pretty print
    } catch (_error) {
      return response.body; // If body is not valid JSON, return as plain text
    }
  })();

  // Dynamically set background color for assertion.message based on assertion status
  const _highlightAssertionMessage = (message: string, passed: boolean) => {
    const highlightClass = passed
      ? 'bg-green-200 dark:bg-green-800 bg-opacity-60'
      : 'bg-red-200 dark:bg-red-800 bg-opacity-60';

    return (
      <span class={`${highlightClass} p-1 rounded`}>
        {message}
      </span>
    );
  };

  return (
    <Accordion title={`Step (Duration: ${duration.toFixed(4)} ms)`}>
      {/* Assertions section */}
      <h3 class="font-bold text-sm sm:text-base md:text-lg mt-4 mb-2 text-[var(--atollee-orange)]">Assertions:</h3>
      {assertions.length > 0 ? (
        assertions.map((assertion, index) => (
          <div key={index} class="mb-2 p-0 rounded-lg max-h-60vh overflow-auto">
            <div class="relative">
              {/* ClipboardButton for Assertions */}
              <ClipboardButton text={JSON.stringify(assertion, null, 2)} />

              {/* Assertions as CodeBlock */}
              <CodeBlock
                code={JSON.stringify(assertion, null, 2)} // Format the entire assertion object as JSON
                language="json"
              />
            </div>
          </div>
        ))
      ) : (
        <p class="
        text-red-500 
        text-sm 
        sm:text-base 
        mb-3
      ">
        No assertions available
      </p>
      )}

      {/* Request section */}
      <h3 class="font-bold text-sm sm:text-base md:text-lg mb-2 text-[var(--atollee-seagreen)]">Request:</h3>
      <CodeBlock code={JSON.stringify(request, null, 2)} language="json" /> {/* Request block */}

      {/* Response section */}
      <h3 class="font-bold text-sm sm:text-base md:text-lg mt-4 mb-2 text-[var(--atollee-blue)]">Response:</h3>
      <CodeBlock
        code={JSON.stringify(
          { status: response.status, statusText: response.statusText, headers: response.headers },
          null,
          2
        )}
        language="json"
      /> {/* Response block */}

      {/* Response body section */}
      <h3 class="font-bold text-sm sm:text-base md:text-lg mt-4 mb-2 text-[var(--atollee-blue)]">Response Body:</h3>
      <CodeBlock code={prettyBody} language="json" /> {/* Response body block */}
    </Accordion>
  );
}
