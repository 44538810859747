import { useState, useEffect, useRef } from "preact/hooks";
import ClipboardButton from "./ClipboardButton.tsx"; // Import ClipboardButton component

// Import Prism.js for syntax highlighting
import "https://cdn.jsdelivr.net/npm/prismjs/prism.js";
import "https://cdn.jsdelivr.net/npm/prismjs/components/prism-javascript.min.js";
import "https://cdn.jsdelivr.net/npm/prismjs/components/prism-typescript.min.js";
import "https://cdn.jsdelivr.net/npm/prismjs/components/prism-json.min.js";

interface PrismType {
  highlightElement: (element: HTMLElement) => void;
}

interface CodeBlockProps {
  code: string;
  language: string; // Specifies the language for syntax highlighting (e.g., 'javascript', 'typescript', 'json')
  noCode?: boolean; // Optional prop to hide code block if true
}

export default function CodeBlock({ code, language, noCode = false }: CodeBlockProps) {
  const codeRef = useRef<HTMLElement>(null);
  const [fontSize, setFontSize] = useState<string>("14px"); // Default font size

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = globalThis.innerWidth;

      // Apply responsive font size based on screen width
      if (screenWidth < 640) {
        setFontSize("12px");
      } else if (screenWidth < 768) {
        setFontSize("14px");
      } else if (screenWidth < 1024) {
        setFontSize("16px");
      } else {
        setFontSize("18px");
      }
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    globalThis.addEventListener("resize", handleResize);

    return () => {
      // Cleanup event listener on component unmount
      globalThis.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (codeRef.current && globalThis.Prism) {
      // Convert Prism to PrismType and highlight the code element
      (globalThis.Prism as unknown as PrismType).highlightElement(codeRef.current);
    }
  }, [code]);

  return (
    <div class="relative pt-0">
      {/* Clipboard Button to copy code to clipboard */}
      <ClipboardButton text={code} />

      {/* Conditionally render the code block if noCode is not true */}
      {!noCode && (
        <pre class="bg-black text-white overflow-auto scrollbar-always pt-0 max-h-[50vh]" style={{ fontSize }}>
          <code ref={codeRef} class={`language-${language}`}>
            {code}
          </code>
        </pre>
      )}
    </div>
  );
}
