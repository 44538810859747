import { useMemo } from 'preact/hooks';
import CodeBlock from "./CodeBlock.tsx"; // Verwende die CodeBlock-Komponente für Syntax-Highlighting
import { JSX } from 'preact'; // Importiere JSX-Typ aus Preact

// Definiere den Typ für Assertions
interface Assertion {
  line: number;
  passed: boolean;
  message: string;
  expected: string | number | boolean | object | null;
  actual: string | number | boolean | object | null;
}

// Definiere den Typ für die Props der Komponente
interface SourceCodeWithLineNumbersProps {
  sourceCode: string;
  lineOffset: number;
  assertions: Assertion[];
}

export default function SourceCodeWithLineNumbers({
  sourceCode,
  lineOffset,
  assertions
}: SourceCodeWithLineNumbersProps) {
  // Convert sourceCode into an array of lines
  const sourceCodeLines = useMemo(() => sourceCode.split('\n'), [sourceCode]);

  // Erstelle eine Map für den schnellen Zugriff auf Assertions pro Zeilennummer
  const assertionMap = useMemo(() => {
    const map: Record<number, Assertion[]> = {};
    assertions.forEach(assertion => {
      const lineNumber = assertion.line;
      if (!map[lineNumber]) {
        map[lineNumber] = [];
      }
      map[lineNumber].push(assertion);
    });
    return map;
  }, [assertions]);

  // Funktion zum Hervorheben von Assertion-Nachrichten im Quellcode
  const highlightAssertionMessage = (
    line: string,
    assertionMessages: string[],
    passed: boolean
  ) => {
    const elements: (string | JSX.Element)[] = []; // Verwende string | JSX.Element statt any
    let remainingLine = line;

    assertionMessages.forEach((message) => {
      const startIndex = remainingLine.indexOf(message);
      if (startIndex !== -1) {
        // Teil der Zeile vor der Assertion-Nachricht hinzufügen
        if (startIndex > 0) {
          elements.push(remainingLine.slice(0, startIndex));
        }

        // Dynamische Hintergrundfarbe basierend auf dem Assertion-Status
        const highlightClass = passed
          ? 'bg-green-200 dark:bg-green-800 bg-opacity-60'
          : 'bg-red-200 dark:bg-red-800 bg-opacity-60';

        // Hervorgehobene Assertion-Nachricht hinzufügen
        elements.push(
          <span class={`${highlightClass} p-1 rounded`}>{message}</span>
        );

        // Verarbeiteten Teil der Zeile entfernen
        remainingLine = remainingLine.slice(startIndex + message.length);
      }
    });

    // Rest der Zeile hinzufügen (falls vorhanden)
    if (remainingLine.length > 0) {
      elements.push(remainingLine);
    }

    return elements;
  };

  return (
    <div class="relative overflow-auto border mb-3 rounded-2xl p-4 bg-gray-50 dark:bg-gray-900">
      {/* Nutze CodeBlock für Syntax-Hervorhebung und den gesamten Source Code */}
     <CodeBlock code={sourceCode} language="javascript" noCode={true} />

      {/* Zeilenweise Darstellung des Source Codes */}
      <pre class="whitespace-pre-wrap text-sm sm:text-base text-gray-800 dark:text-gray-100">
        {sourceCodeLines.map((line, _index) => {
          const lineNumber = _index + lineOffset;
          const assertionsForLine = assertionMap[lineNumber];

          const assertionMessages = assertionsForLine ? assertionsForLine.map(a => a.message) : [];
          const highlightedLine = assertionsForLine && assertionMessages.length
            ? highlightAssertionMessage(line, assertionMessages, assertionsForLine[0].passed)
            : line;

          const containsAssertExists = line.includes("assertExists(");
          const hasAssertion = assertionsForLine && assertionsForLine.some(assertion => assertion.actual !== null);

          let backgroundColorClass = '';
          if (assertionsForLine) {
            backgroundColorClass = 'bg-yellow-50 dark:bg-yellow-900';
          } else if (containsAssertExists && !hasAssertion) {
            backgroundColorClass = 'bg-pink-100 bg-opacity-50 dark:bg-pink-900';
          }

          return (
            <div key={lineNumber} class={`flex justify-between space-x-4 ${backgroundColorClass}`}>
              {/* Line number and status icon */}
              <div class="flex items-start space-x-2">
                <span class="text-gray-400 dark:text-gray-500 select-none">
                  {lineNumber}{' '}
                  {assertionsForLine && (assertionsForLine[0].passed ? '✅' : '❌')}
                </span>
                <span class="flex-1">
                  {highlightedLine}
                </span>
              </div>

              {/* Render Expected and Actual in the same line, right-aligned */}
              {assertionsForLine && (
                <div class="flex items-start text-xs space-x-4">
                  {assertionsForLine.map((assertion, i) => (
                    <div key={i} class="flex flex-col text-right">
                      {/* Kopiere den jeweiligen Assertion JSON */}
                      <p class={`translate-y-1 ${assertion.passed ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                        🎯 {JSON.stringify(assertion.expected)}
                      </p>
                      <p class={`translate-y-1 ${assertion.passed ? 'text-green-600 dark:text-green-400' : 'text-red-600 dark:text-red-400'}`}>
                        👁️ {JSON.stringify(assertion.actual)}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          );
        })}
      </pre>
    </div>
  );
}
